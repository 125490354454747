<!--新增冻结单-->
<template>
  <section class="update_section">
    <el-dialog
        :visible.sync="dialogVisible"
        destroy-on-close
        width="70%"
        @close="handleColse"
        v-bind="$attrs">
      <el-form :model="formObj" :rules="rules" label-position="right" label-width="80px" ref="formRef">
        <div>
          <div class="title">基础信息</div>
          <el-form-item label="冻结原因" prop="notes" class="mb20">
            <el-input v-model="formObj.notes" placeholder="请输入" show-word-limit :maxlength="100" type="textarea"/>
          </el-form-item>
          <el-form-item label="原因描述" prop="remark">
            <el-input type="textarea" v-model="formObj.remark" :rows="4" :maxlength="300" show-word-limit placeholder="请输入"/>
          </el-form-item>
        </div>
        <div class="product_table_list">
          <div class="title">产品信息</div>
          <el-table ref="detailTable" height="calc(100vh - 480px)" stripe highlight-current-row :data="formObj.allData" style="width: 100%;" :header-cell-style="$parent.tableHeaderColor" border>
            <el-table-column type="index" label="序号" :key="1" width="50"> </el-table-column>
            <el-table-column prop="imageUrl" :key="2" :label="$t(`m.common.picture`)" width="120">
                <template slot-scope="scope">
                <el-image style="width: 80px; height: 80px" :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]">
                </el-image>
                </template>
            </el-table-column>
            <el-table-column :key="3" prop="itemCode" label="SKU" width="260">
                <template slot-scope="scope">
                <div v-if="currentRow.pageType === 'detail'">{{ scope.row.itemCode||'-' }}</div>
                <div v-else>
                    <el-form-item class="mb0" v-if="scope.$index >= 0" label-width="0" :prop="'allData.' + scope.$index + '.itemCode'"
                      :rules="rules.itemCode">
                      <el-select size="small" clearable class="w100" filterable v-model="scope.row.itemCode" placeholder="请输入" @change="(v) => itemCodeChange(v,scope.row)">
                          <el-option v-for="(iInfo,index) in allSkuList" :key="index" :label="iInfo.itemCode" :value="iInfo.itemCode">
                          </el-option>
                      </el-select>
                    </el-form-item>
                </div>
                </template>
            </el-table-column>
            <el-table-column :key="10" prop="itemName" show-overflow-tooltip :label="$t(`m.common.product_name`)" width="200">
                <template slot-scope="scope">
                {{ scope.row.itemName||'-' }}
                </template>
            </el-table-column>
            <el-table-column :key="4" prop="startCell" label="库位编码" width="200">
                <template slot-scope="scope">
                <el-form-item class="mb0" v-if="scope.$index >= 0 && currentRow.pageType !== 'detail'" label-width="0" :prop="'allData.' + scope.$index + '.startCell'" :rules="rules.cellCode">
                    <el-select class="w100" filterable size="small" v-model="scope.row.startCell" placeholder="请选择"
                            @change="(v) => selectCellCodeChange(v,scope.row)">
                    <el-option v-for="(iInfo,cellIndex) in getCellInfos(scope.row.itemCode, scope.row.skuCellInfos)" :key="cellIndex" :label="iInfo.cellCode" :value="iInfo.cellCode">
                    </el-option>
                    </el-select>
                </el-form-item>
                <span v-else>{{ scope.row.startCell  }}</span>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :key="5" prop="cellUseType" label="库存属性">
                <template slot-scope="scope">
                <div v-if="scope.row.cellUseType">
                    <el-button type="text" class="my-warning" v-if="scope.row.cellUseType == 5||scope.row.cellUseType == 6">次品</el-button>
                    <el-button type="text" class="my-success" v-else>良品</el-button>
                </div>
                <div v-else>-</div>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :key="6" prop="kykc" label="可用库存"> </el-table-column>
            <el-table-column :key="7" prop="quantity" label="冻结数量" width="200">
                <template slot-scope="scope">
                  <div class="flex" v-if="currentRow.pageType !== 'detail'">
                      <el-form-item class="mb0" style="width: 100%;" v-if="scope.$index >= 0" label-width="10" :prop="'allData.' + scope.$index + '.quantity'"
                                  :rules="rules.quantity">
                        <el-input-number class="w100" size="small" v-model="scope.row.quantity" controls-position="right" placeholder="请输入"
                          :min="1" :precision="0"></el-input-number>
                      </el-form-item>
                  </div>
                  <div v-else>
                      <div>{{ scope.row.quantity }}</div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t(`m.common.operation`)" fixed="right" min-width="100" :key="9" v-if="currentRow.pageType !== 'detail'">
                <template slot-scope="scope">
                <el-button type="text" @click="addBtn(scope.row)" v-if="scope.$index == 0">添加</el-button>
                <el-button type="text" class="my-danger" @click="deleteBtn(scope.row, scope.$index)" :disabled="formObj.allData.length == 1">移除</el-button>
                </template>
            </el-table-column>
            </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {Message} from "element-ui";
import _ from 'lodash'

export default {
  name: 'AddFreeUpdate',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      wareId: PF.getLocal('wareId'),
      wareCode: PF.getLocal('wareCode'),
      formObj: {
        allData: [{}]
      },
      rules: {
        notes: [{required: true, message: '请输入', trigger: 'blur'}],
        itemCode: [{required: true, message: '请输入', trigger: 'blur'}],
        startCell: [{required: true, message: '请输入', trigger: 'blur'}],
        quantity: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      allSkuList: [],
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    },
    getCellInfos() {
        return (itemCode, skuCellInfos) => {
            let isCellCode = _.filter(this.formObj.allData, i => i.itemCode == itemCode)
            if (isCellCode.length > 0) {
                // 过滤相同SKU并且库位编码也相同的  this.allCellInfos --- 所有的库位
                return _.filter(skuCellInfos, i => !_.includes(_.map(isCellCode, ii => ii.startCell), i.cellCode))
            }
            return skuCellInfos
        }
    },
    isCheck() {
        return (id) => {
            const customIndexList = _.map(this.multipleSelection, item => item.id)
            return _.includes(customIndexList, id)
        }
    },
  },
  created() {
  },
  methods: {
    handleColse() {
      this.formObj = {
        allData: [{}]
      }
      this.$refs.formRef.resetFields();
      this.$emit('submitClose')
    },
    // 选择sku
    async itemCodeChange(v, row) {
      console.log(row, 'row');
      
        let obj = _.find(this.allSkuList, item => item.itemCode == v)
        if (!_.isEmpty(obj)) {
            row.itemName = obj.itemName
            row.imageUrl = obj.imgUrl
        }
        this.getSkuCellInfos(row)
        this.getAcceptCell(row)
    },
    // 选择库位编码
    selectCellCodeChange(v, row) {
      console.log(row, 'row');
        this.getAcceptCell(row)
    },
    addBtn(row) {
        this.formObj.allData.push({
            selectType: 1
        })
    },
    deleteBtn(row, index) {
        this.formObj.allData.splice(index, 1);
    },
    // 获取所有sku数据源
    getSkuList() {
        IOT.getServerData('/item/infos/list', 'post', {
            pageNum: 1,
            pageSize: 10000,
            total: null,
            wareCode: this.wareCode
        }, ret => {
            if (ret.code === 200) {
                let rows = ret.rows;
                this.allSkuList = _.map(rows, item => {
                    return item
                });
            } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
        });
    },
    // 获取sku绑定的库位数据源
    getSkuCellInfos(row) {
        let param = {
            pageNum: 1,
            pageSize: 10000,
            total: null,
            wareId: this.wareId,
            wareCode: this.wareCode,
            keyWord: row.itemCode ? row.itemCode.split(/,|，+/).join(',') : null,
        };
        IOT.getServerData('/inventoryMaster/inventoryList', 'post', param, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
            if (ret.rows.length) {
              this.$set(row, 'startCell', '')
              this.$set(row, 'skuCellInfos', _.filter(ret.rows||[], i => i.cellUseType != '5' && i.cellUseType != '6'))
            } else {
              this.$set(row, 'skuCellInfos', [])
            }
        } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
        }
        });
    },
    // 通过sku加库位 查询 对应库存属性和可用库存
    getAcceptCell(row) {
        let param = {
            pageNum: 1,
            pageSize: 10000,
            total: null,
            wareId: this.wareId,
            wareCode: this.wareCode,
            keyWord: row.itemCode ? row.itemCode.split(/,|，+/).join(',') : null,
            cellCode: row.startCell ? row.startCell : null,
        };
        IOT.getServerData('/inventoryMaster/inventoryList', 'post', param, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
            let obj = _.find(this.allCellInfos, item => item.cellCode == row.startCell)
            if (!_.isEmpty(obj)) {
                this.$set(row, 'cellUseType', obj.cellUseType)
            }
            if (ret.rows.length) {
                this.$set(row, 'kykc', ret.rows[0].useQuantity)
            } else {
                this.$set(row, 'kykc', 0)
            }
        } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
        }
        });
    },
    // 获取所有库位数据源
    getAllAcceptCell() {
      let param = {
          wareId: this.wareId,
          wareCode: this.wareCode,
      };
      IOT.getServerData('/cell/infos/lableList', 'post', param, (ret) => {
      IOT.hideOverlay();
      if (ret.code === 200) {
          this.allCellInfos = _.flatten(_.map(_.filter(ret.rows, i => i.labelCode !== 0), ii => ii.dtoList)); // 过滤调验收位
      } else {
          IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
      }
      });
    },
    // 提交
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // 校验冻结数量大于可用库存
          const itemCodeNum = _.filter(this.formObj.allData, item => item.quantity > item.kykc || !item.quantity)
          if (itemCodeNum.length > 0) {
              this.$message.warning(`当前${_.map(itemCodeNum, i => i.itemCode).join(',')}库位可用库存不足以进行冻结！`)
              return
          }
          // 调用接口
          window.IOT.showOverlay('提交中～～～');
          let notes = this.formObj.remark ? this.formObj.notes + '/' + this.formObj.remark : this.formObj.notes 
          let allData = {
              ...this.formObj,
              freezeInventoryList: this.formObj.allData,
              wareId: this.wareId,
              wareCode: this.wareCode,
              notes
          }
          IOT.getServerData('/freeze/inventory/add', 'post', {...allData}, ret => {
              window.IOT.hideOverlay();
              if (ret.code === 200) {
                  window.IOT.tips('提交成功！', 'success');
                  this.formObj = { allData: [{}] }
                  this.dialogVisible = false
              } else {
                  window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
              }
          });
        } else {
          Message.warning('请填写必填项！')
        }
      })
    }
  },
  mounted() {
    this.getSkuList()
    this.getAllAcceptCell()
  }
}
</script>

<style scoped lang="less">
.update_section {
  .mb20 {
    margin-bottom: 20px;
  }
  .title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .title:before {
    content: ' ';
    background-color: #3276b1;
    padding-right: 4px;
    margin-right: 10px;
  }

  .product_table_list {
    margin-top: 20px;
    :deep(.el-form-item) {
      margin-bottom: 0;
    }
  }
}
</style>
