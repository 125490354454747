<!--库存冻结页面-->
<template>
  <div class="free_stock_content">
    <div ref="commonSearchRef">
      <div class="publicSearch p-10">
        <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
          <div>
            <el-input style="width: 300px;" v-model="searchData.keyWord" size="mini" :placeholder="$t(`m.inventoryCommon.batch_search`)" clearable/>
          </div>
          <div>
            <el-button @click="getList" size="mini" icon="el-icon-search" type="primary">{{ $t(`m.common.search`) }}</el-button>
            <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">{{ $t(`m.common.reset`) }}</el-button>
          </div>
        </div>
      </div>
      <div class="headerBtns">
        <el-button size="small" type="primary" icon="iconfont icon-xinzeng"
                  @click="handleAddFreeOrder">
          {{ $t(`m.inventoryCommon.add_freeze_order`) }}
        </el-button>
      </div>
    </div>
    <div class="tableBox">
      <el-table
          ref="commonTableRef"
          tooltip-effect="dark myTooltips"
          :row-style="$parent.tableRowStyle"
          highlight-current-row
          stripe
          border
          :header-cell-style="$parent.tableHeaderColor"
          v-loading="loading.masterTable"
          :data="asnBills"
          @selection-change="handleSelectionChange"
          style="width: 100%"
          row-key="billNo"
          :expand-row-keys="expandRowKeys"
          :height="dynamicsHeight(commonSearchRef, true, true, 10)"
          @expand-change="(a,b) => handleExpandChange(a,b, 'item')"
      >
        <el-table-column type="index" :label="$t(`m.common.no`)" width="50"/>
        <el-table-column type="expand" key="1">
          <template slot-scope="scope" v-if="scope.row.detailList">
            <div class="p-10">
              <el-table :data="scope.row.detailList" border style="width: 100%">
                <el-table-column prop="imageUrl" :label="$t(`m.common.picture`)">
                  <template slot-scope="scope">
                    <el-image style="width: 63px; height: 63px; " :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="itemCode" label="SKU"> </el-table-column>
                <el-table-column prop="itemName" :label="$t(`m.common.product_name`)"> </el-table-column>
                <el-table-column prop="startCell" :label="$t(`m.inventoryCommon.storage_location`)"> </el-table-column>
                <el-table-column prop="quantity" :label="$t(`m.inventoryCommon.frozen_quantity`)"> </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="billNo" :label="$t(`m.inventoryCommon.cancel_order_number`)" min-width="170" key="2"/>
        <el-table-column show-overflow-tooltip prop="state" :label="$t(`m.inventoryCommon.status`)" min-width="100" key="3">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1" class="my-warning">{{ $t(`m.inventoryCommon.frozen_place`) }}</span>
            <span v-else-if="scope.row.state == 2" class="my-success">{{ $t(`m.inventoryCommon.unfrozen`) }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column key="4" show-overflow-tooltip prop="notesStr" :label="$t(`m.inventoryCommon.freezing_reason`)" min-width="150">
        </el-table-column>  
        <el-table-column show-overflow-tooltip prop="remark" :label="$t(`m.inventoryCommon.reason_desc`)" min-width="200" key="5">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createUserName" :label="$t(`m.common.creator`)" min-width="260"/>
        <el-table-column show-overflow-tooltip prop="createTime" :label="$t(`m.common.creation_time`)" min-width="260"/>
        <el-table-column :label="$t(`m.common.operation`)" fixed="right" width="85">
          <template slot-scope="scope">
            <el-button class="my-warning" type="text" @click="unFreeze(scope.row)" v-if="scope.row.state == 1">
              {{ $t(`m.inventoryCommon.thawing`) }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="pagination-bottom"
          style="text-align: right;"
          @current-change="handleCurrentChange"
          :current-page.sync="searchData.pageNum"
          :page-size="searchData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="searchData.total"
      >
      </el-pagination>
    </div>
    <Update :visible.sync="updateObj.show" :currentRow="currentRow" :title="$t(`m.inventoryCommon.add_freeze_order`)" top="5vh" @submitClose="submitClose"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import { formatListDataByKey, dynamicsHeight } from '@/utils/DateUtils.js'
import {Message} from "element-ui";
import Update from './components/Update.vue'

export default {
  name: 'AsnBill',
  components: { Update },
  data() {
    return {
      commonSearchRef: null,
      wareId: PF.getLocal('wareId'),
      loading: {
        masterTable: true,
      },
      isMoreSearch: false,
      asnBills: [],
      searchData: {
        pageNum: 1,
        pageSize: 15,
        total: null,
        keyWord: '',
        wareCode: PF.getLocal('wareCode')
      },
      expandRowKeys: [],
      updateObj: {
        show: false
      },
      customPageObj: {
        show: false,
        formObj: {},
        rules: {
          zdyyrl: [{ required: true, message: '请输入', trigger: 'blur' }]
        }
      },
      currentRow: {}
    };
  },
  watch: {
  },
  computed: {
    dynamicsHeight,
    ...mapState([])
  },
  methods: {
    formatListDataByKey,
    submitClose() {
      this.getList()
    },
    // 新增冻结单
    handleAddFreeOrder() {
      this.currentRow = { pageType: 'add' }
      this.updateObj.show = true
    },
    // 查询列表所有的数据
    getList() {
      var that = this;
      let searchData = PF.JSON(this.searchData);
      this.expandRowKeys = []
      that.loading.masterTable = true;
      let { keyWord } = this.searchData
      keyWord = keyWord ? keyWord.split(/,|，|\s+/).join(',') : null
      // asnType 1预约入库单 2 入库单
      IOT.getServerData('/freeze/inventory/list', 'post', {...searchData, keyWord}, ret => {
        that.loading.masterTable = false;
        if (ret.code === 200) {
          var rows = ret.rows;
          this.asnBills = _.map(rows||[], i => {
            i.notesStr = i.notes ? i.notes.split('/')[0] : ''
            i.remark = i.notes ? i.notes.split('/')[1] : ''
            return i
          });
          this.searchData.total = ret.total;
          this.searchData.pageNum = ret.pageNumber;
        } else {
          IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
        }
      });

      this.$nextTick(() => {
        // 强制重新计算表格高度
        this.$refs.commonTableRef.doLayout();
      });
    },
    // 解冻
    unFreeze(row) {
      this.$confirm(this.$t(`m.inventoryCommon.sure_unfreeze`))
      .then(() => {
        IOT.getServerData('/freeze/inventory/liftFreeze', 'post', {billNo: row.billNo}, ret => {
          window.IOT.hideOverlay();
          if (ret.code === 200) {
            Message.success(this.$t(`m.inventoryCommon.thawing_successfully`))
            this.getList()
          } else {
            window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      })
      .catch(() => {});
    },
    // 单独点击展开触发
    handleExpandChange(row, expandList, type = 'item') {
      const obj = _.find(expandList, item => item.billNo === row.billNo)
      this.expandRowKeys = _.map(expandList || [], (it) => it.billNo);
      if (expandList.length > 0 && ((type === 'item' && obj) || type === 'all')) {
        let url = (url = '/asn/details/list');
        console.log('展开触发>>>', url)
      }
    },
    handleSizeChange(val) {
      // 缓存到localStorage
      PF.setLocal('asnPageSize', val)
      this.searchData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.searchData.pageNum = val;
      this.getList();
    },
    clearSearchData() {
      this.searchData.keyWord = '';
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelItem(it, index = 0) {
      const pageSizes = this.searchData.pageSizes
      if(pageSizes.length === 1) {
        Message.warning(this.$t(`m.inventoryCommon.retain_data`))
        return
      }
      // 当前使用的不能删除
      if(it === this.searchData.pageSize) {
        Message.warning(this.$t(`m.inventoryCommon.data_used`))
        return
      }

      this.searchData.pageSizes = _.filter(pageSizes, (_, itx) => itx !== index)
      // 保持下拉框不马上关闭
      this.$refs.selectRef.focus()
    },
    handleCustomPageSizes() {
      this.customPageObj.show = true
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.commonSearchRef = this.$refs.commonSearchRef
    })
  },
};
</script>

<style scoped lang="less">
.free_stock_content {

}
</style>
